import { Component, Input } from '@angular/core';
import { PricingCard } from '../../models/pricing-card.model';
import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';

@Component({
  selector: 'price-card',
  standalone: true,
  imports: [NgClass, TitleCasePipe, NgFor, NgIf],
  templateUrl: './price-card.component.html',
  styleUrl: './price-card.component.scss',
})
export class PriceCardComponent {
  @Input() pricingCard!: PricingCard;
}
