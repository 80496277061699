import { Component } from '@angular/core';
import { GetInTouchComponent } from '../get-in-touch/get-in-touch.component';
import { GetInTouchDetailsComponent } from '../get-in-touch-details/get-in-touch-details.component';

@Component({
  selector: 'app-join-us',
  standalone: true,
  imports: [GetInTouchComponent, GetInTouchDetailsComponent],
  templateUrl: './join-us.component.html',
  styleUrl: './join-us.component.scss'
})
export class JoinUsComponent {

}
