<div class="flex flex-col py-14 px-4 md:px-28 lg:px-72 gap-10">
  <div class="flex flex-col gap-5 items-start justify-start md:items-center md:justify-center">
    <p class="text-hurrey-20 lg:text-hurrey-24 text-black font-bold"> Check Eligibility</p>
    <p class="text-hurrey-16"> Fill the details below and we will reach out to you</p>
  </div>


  <form class="grid grid-cols-2 gap-4 md:gap-6" [formGroup]=" checkEligibilityForm">

    <div class="col-span-2 flex flex-col gap-2">
      <label class="text-hurrey-16 text-black font-bold "> School Name</label>
      <cust-input [required]="true" [placeholder]="'Enter your school name'" formControlName="school_name"
        [fieldName]="'school_name'" [type]="'text'"></cust-input>
    </div>

    <div class="col-span-2 flex flex-col gap-2">
      <label class="text-hurrey-16 text-black font-bold "> School Address</label>
      <cust-input [required]="true" [placeholder]="'Enter your school address'" formControlName="school_address"
        [fieldName]="'school_address'" [type]="'text'"></cust-input>
    </div>

    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <label class="text-hurrey-16 text-black font-bold "> Phone number</label>
      <cust-input [required]="true" [placeholder]="'+91 (555) 000-0000'" formControlName="phone_number"
        [fieldName]="'phone_number'" [type]="'text'"></cust-input>
    </div>


    <div class=" col-span-2 md:col-span-1 flex flex-col gap-2">
      <label class="text-hurrey-16 text-black font-bold "> Email</label>
      <cust-input [required]="true" [placeholder]="'abc@gmail.com'" formControlName="email" [fieldName]="'email'"
        [type]="'text'"></cust-input>
    </div>

    <div class=" col-span-2 md:col-span-1 flex flex-col gap-2">
      <label class="text-hurrey-16 text-black font-bold "> Pin code</label>
      <cust-input [required]="true" [placeholder]="'Enter pin code'" formControlName="pin_code" [fieldName]="'pin_code'"
        [type]="'text'"></cust-input>
    </div>

    <div class=" col-span-2 md:col-span-1 flex flex-col gap-2">
      <label class="text-hurrey-16 text-black font-bold "> Country</label>
      <select formControlName="country" class="drop_down">
        <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
      </select>
    </div>

    <div class=" col-span-2 md:col-span-1 flex flex-col gap-2">
      <label class="text-hurrey-16 text-black font-bold "> Select State</label>
      <select formControlName="state" class="drop_down">
        <option *ngFor="let state of states" [value]="state">{{ state }}</option>
      </select>
    </div>

    <div class=" col-span-2 md:col-span-1 flex flex-col gap-2">
      <label class="text-hurrey-16 text-black font-bold "> Select City/Town</label>
      <select formControlName="select_city" class="drop_down">
        <option *ngFor="let city of city" [value]="city">{{ city }}</option>
      </select>
    </div>
    <div class=" col-span-2 md:col-span-1 flex flex-col gap-2">
      <label class="text-hurrey-16 text-black font-bold "> School Board</label>
      <select formControlName="school_board" class="drop_down">
        <option *ngFor="let board of school_board" [value]="board">{{ board }}</option>
      </select>
    </div>
    <div class=" col-span-2 md:col-span-1 flex flex-col gap-2">
      <label class="text-hurrey-16 text-black font-bold "> Founded In</label>
      <select formControlName="founded_in" class="drop_down">
        <option *ngFor="let year of founded_in" [value]="year">{{ year }}</option>
      </select>
    </div>

  </form>

  <div class="flex gap-5">

    <input type="checkbox" id="smart_classes" name="smart_classes" value="smart_classes" class="checkbox-input"
      [(ngModel)]="smart_classes_checked">
    <p class="text-hurrey-16 text-grey">Do you have smart classes in your School? </p>
  </div>

  <div class="flex gap-5">
    <input type="checkbox" id="school_app" name="school_app" value="school_app" class="checkbox-input"
      [(ngModel)]=" school_app_checked">
    <p class="text-hurrey-16 text-grey">Do you use any School App?</p>
  </div>

  <div class="grid grid-cols-4 gap-4 md:gap-10">
    <div class="col-span-4 md:col-span-3">
      <p class="text-grey text-hurrey-16"> Verify all information are correct before clicking submit.</p>
    </div>
    <div class="col-span-4 md:col-span-1 flex items-center justify-center md:items-end md:justify-end">
      <button class="text-hurrey-16 text-white submit_button"> Submit</button>
    </div>
  </div>



</div>