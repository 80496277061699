<section class="trusted_by_customers_container">
  <div class="flex flex-col pt-20 md:pt-12 lg:pt-16 gap-16 md:gap-20 pb-12  px-4 md:px-14 lg:px-44  ">
    <div [@slideInDown]>
      <div class="flex flex-col items-center justify-center">
        <p class="text-purple_100 text-hurrey-16 lg:text-hurrey-28 mb-1">Trusted</p>
        <p class="text-purple_100 text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48 mb-1"> by 50 +</p>
        <p class="text-black text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48"> Customers</p>
      </div>
    </div>

    <div [@slideInOut] class="custom_container gap-4 lg:gap-6">
      <div *ngFor="let customer of customers">
        <div class="customers_card rounded-md lg:rounded-xl ">
          <div class="flex flex-col gap-2 lg:gap-8 pl-3 lg:pl-6 pt-3 lg:pt-6 pb-4">
            <div class="flex flex-col gap-2 lg:gap-4 ">
              <p class="text-hurrey-16 lg:text-hurrey-20  text-black text-wrap" [id]="'customer_'+customer.title">
                "{{customer.title}}"</p>
              <p class="text-hurrey-12 lg:text-hurrey-16 text-grey text-wrap"
                [id]="'customer_'+customer.title+'_decription'"> {{customer.description}}</p>
            </div>
            <div class="flex gap-2 items-center">
              <div class="customer_image h-10 w-10 rounded-xxl">
                <img [src]="customer.imgUrl" [alt]="customer.name" class="object-fill rounded-xxl">
              </div>
              <div class="flex flex-col gap-1">
                <p class="text-hurrey-12 text-black" [id]="'customer_'+customer.name">{{customer.name}}</p>
                <p class="text-hurrey-12 text-grey" [id]="'customer_'+customer.name+'_designation'">
                  {{customer.designation}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</section>