<section id="main_pricing_container"
  class="h-screen slide px-4 md:px-14 lg:px-[108px] flex items-center relative bg-blue_100">
  <div class="flex flex-col gap-6" [@fadeInLeftOnEnter]>
    <div class="flex flex-col gap-4 text-white">
      <p class="text-hurrey-28 lg:text-hurrey-48" id="pricing_page_main_head">Discover Atomo's <br
          class="block md:hidden">
        Competitive <br class="hidden md:block">Pricing <br class="block md:hidden"> Structure</p>
    </div>
    <p class="text-hurrey-16 text-white" id="pricing_page_main_description">Nullam in mauris quis lacus luctus tempor
      vel finibus <br class="hidden md:block lg:hidden"> nunc. Vestibulum cursus
      sed nisi <br class="hidden lg:block"> non
      faucibus. Fusce <br class="hidden md:block lg:hidden"> laoreet
      sapien tellus, a tincidunt risus lacinia sed</p>
  </div>
</section>

<section class="pricing_container mb-9 px-4 md:px-14 lg:px-28 py-16">

  <div class="flex flex-col justify-center items-center gap-4 mb-6 lg:mb-10">
    <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36">Powerful Features For <span
        class="text-amber_100">You</span></p>
    <p class="text-hurrey-16 lg:text-hurrey-24">Choose a plan that’s right for you</p>
  </div>

  <div class="flex justify-center  md:items-center gap-4 mb-14">

    <p>Monthly</p>

    <div>
      <input type="checkbox" id="toggle-btn-1" [value]="yearly" [checked]="yearly">
      <label for="toggle-btn-1"></label>
    </div>

    <div class="flex flex-col md:flex-row gap-1">
      <p>Yearly</p>
      <p class="text-amber_100">(Save upto 25%)</p>
    </div>
  </div>

  <div class="flex flex-col md:flex-row items-center gap-6 justify-stretch w-full">



    <price-card [pricingCard]="pricingCard" *ngFor="let pricingCard of pricingCards"></price-card>


  </div>

</section>