import { Routes } from '@angular/router';
import { AtomosLayoutComponent } from './core/atomos-layout/atomos-layout.component';
import { LandingComponent } from './modules/landing/landing/landing.component';
import { UseCasesComponent } from './modules/inner-pages/use-cases/use-cases.component';
import { PricingComponent } from './modules/inner-pages/pricing/pricing.component';
import { AtomoProductsComponent } from './modules/inner-pages/atomo-products/atomo-products.component';
import { CompanyComponent } from './modules/inner-pages/company/company.component';
import { JoinUsComponent } from './modules/join-us/join-us/join-us.component';
import { CheckEligibilityComponent } from './modules/check-eligiblity/check-eligibility/check-eligibility.component';
import { MathSolverComponent } from './modules/inner-pages/math-solver/math-solver.component';
import { PhysicsSolverComponent } from './modules/inner-pages/physics-solver/physics-solver.component';
import { LanguageLearnerComponent } from './modules/inner-pages/language-learner/language-learner.component';
import { HurreyScopeComponent } from './modules/inner-pages/hurrey-scope/hurrey-scope.component';
import { BlogsComponent } from './modules/blogs/blogs/blogs.component';
import { BlogComponent } from './modules/blogs/blog/blog.component';
import { TermsAndConditionsComponent } from './modules/legal/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './modules/legal/privacy-policy/privacy-policy.component';

export const routes: Routes = [
  // {'',pathMatch:'full',redirectTo:AtomosLayoutComponent},
  {
    path: '',
    component: AtomosLayoutComponent,
    children: [
      { path: '', component: LandingComponent },
      { path: 'use-cases', component: UseCasesComponent ,},
      { path: 'pricing', component: PricingComponent },
      { path: 'products', component: AtomoProductsComponent },
      { path: 'company', component: CompanyComponent },
      { path: 'join-us', component: JoinUsComponent },
      { path: 'check-eligiblity', component: CheckEligibilityComponent },
      { path: 'math-solver', component: MathSolverComponent },
      { path: 'physics-solver', component: PhysicsSolverComponent },
      { path: 'language-learner', component: LanguageLearnerComponent },
      { path: 'hurrey-scope', component: HurreyScopeComponent },
      { path: 'blogs', component: BlogsComponent },
      { path: 'blogs/:slug', component: BlogComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
    ],
  },
  { path: '**', redirectTo: '' },
];
