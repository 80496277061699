import { Component } from '@angular/core';

@Component({
  selector: 'eligible',
  standalone: true,
  imports: [],
  templateUrl: './eligible.component.html',
  styleUrl: './eligible.component.scss'
})
export class EligibleComponent {

}
