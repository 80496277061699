<div class=" h-screen join_us_container flex  px-5 lg:px-[108px] md:px-14   ">
  <div class="grid grid-cols-2 gap-8 lg:gap-28 md:gap-20">
    <div class="col-span-2 md:col-span-1 flex flex-col gap-4 items-start justify-center">
      <p class=" text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-48 text-white">Are you eligible?</p>
      <p class="text-hurrey-16 text-white"> As your school undergoes the AI upgrade, a neighboring school also receives
        a complimentary upgrade. It's a dual
        transformation, enhancing both your institution and the local educational landscape, fostering a technologically
        empowered future for all.</p>
    </div>
    <div class="col-span-2 md:col-span-1 flex items-end justify-center">
      <img src="../../../../assets/images/check_eligibility_women.webp" alt="experts-image" class="images_container">
    </div>
  </div>
</div>