import { NgClass, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { FieldsErrorComponent } from '../fields-error/fields-error.component';
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl, NgModel } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'cust-input',
  standalone: true,
  imports: [NgClass, NgStyle, NgIf, FieldsErrorComponent],
  templateUrl: './custom-input.component.html',
  styleUrl: './custom-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CustomInputComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomInputComponent implements ControlValueAccessor, OnInit {

  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';
  @Input() type: 'text' | 'email' | 'password' = 'text';
  @Input() required: boolean = true;
  @Input() fieldName: string = '';

  public control!: FormControl;
  protected readonly destroy = new Subject<void>();

  public onChange = (value: any | null): any | null => value;

  public onTouch = (): void => { };

  // onChange = (value: any) => {};
  onTouched = () => { };

  value: any = '';

  constructor(@Inject(Injector) private injector: Injector) { }

  ngOnInit(): void {
    this.setComponentControl();
  }



  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.onChange(value);
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onchange(event: any) {
    this.value = event.target.value;
    this.onChange(this.value);
    this.onTouched();
  }

  private setComponentControl(): void {
    try {
      const formControl = this.injector.get(NgControl);

      switch (formControl.constructor) {
        case NgModel: {
          const { control, update } = formControl as NgModel;

          this.control = control;

          this.control.valueChanges
            .pipe(
              tap((value: any) => update.emit(value)),
              takeUntil(this.destroy)
            )
            .subscribe();
          break;
        }
        case FormControlName: {
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;
        }
        default: {
          this.control = (formControl as FormControlDirective)
            .form as FormControl;
          break;
        }
      }
    } catch (error) {
      this.control = new FormControl();
    }
  }

  //genrateRandomId

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

}
