import { Component } from '@angular/core';
import { CustomInputComponent } from '../../../shared/reusable-components/custom-input/custom-input.component';

@Component({
  selector: 'get-in-touch',
  standalone: true,
  imports: [],
  templateUrl: './get-in-touch.component.html',
  styleUrl: './get-in-touch.component.scss'
})
export class GetInTouchComponent {

}
