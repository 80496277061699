import { Component } from '@angular/core';
import { CustomInputComponent } from '../../../shared/reusable-components/custom-input/custom-input.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { state } from '@angular/animations';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'check-eligiblity-form',
  standalone: true,
  imports: [CommonModule, CustomInputComponent, ReactiveFormsModule, FormsModule],
  templateUrl: './check-eligiblity-form.component.html',
  styleUrl: './check-eligiblity-form.component.scss'
})
export class CheckEligiblityFormComponent {

  showDropdown = false;
  smart_classes_checked: boolean = false;
  school_app_checked: boolean = false;

  countryCodes = [
    { code: '+91', name: 'India' },
    { code: '+1', name: 'United States' },
  ];


  selectedCountryCode = new FormControl(this.countryCodes[0].code);

  updatePlaceholder() {
    const selectedCountry = this.countryCodes.find(country => country.code === this.selectedCountryCode.value);
    return selectedCountry ? `${selectedCountry.code}` : 'Enter your phone number';
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }



  countries: string[] = ['India', 'USA', 'Canada', 'Australia', 'UK'];
  states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
  ];
  school_board: string[] = [
    "Central Board of Secondary Education (CBSE)",
    "Indian Certificate of Secondary Education (ICSE)",
    "State Boards (e.g., Maharashtra State Board of Secondary and Higher Secondary Education, Tamil Nadu State Board, etc.)",
    "National Institute of Open Schooling (NIOS)",
    "International Baccalaureate (IB)",
    "Cambridge Assessment International Education (CAIE)"
  ];

  city: string[] = [
    "Mumbai",
    "Delhi",
    "Bangalore",
    "Kolkata",
    "Chennai",
    "Hyderabad",
    "Ahmedabad",
    "Pune",
    "Surat",
    "Jaipur",
    "Lucknow",
    "Kanpur",
    "Nagpur",
    "Indore",
    "Thane",
    "Bhopal",
    "Visakhapatnam",
    "Pimpri-Chinchwad",
    "Patna",
    "Vadodara"
  ];

  founded_in: string[] = [
    '2023',
    '2022',
    '2024'

  ]





  checkEligibilityForm: FormGroup = new FormGroup({
    school_name: new FormControl('', Validators.required),
    school_address: new FormControl('', Validators.required),
    phone_number: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    pin_code: new FormControl('', Validators.required),
    country: new FormControl(this.countries[0], Validators.required),
    state: new FormControl(this.states[0], Validators.required),
    select_city: new FormControl(this.city[0], Validators.required),
    school_board: new FormControl(this.school_board[0], Validators.required),
    founded_in: new FormControl(this.founded_in[0], Validators.required),

  });








}
