import { Component } from '@angular/core';
import { CheckEligiblityFormComponent } from '../check-eligiblity-form/check-eligiblity-form.component';
import { EligibleComponent } from '../eligible/eligible.component';

@Component({
  selector: 'app-check-eligibility',
  standalone: true,
  imports: [CheckEligiblityFormComponent, EligibleComponent],
  templateUrl: './check-eligibility.component.html',
  styleUrl: './check-eligibility.component.scss'
})
export class CheckEligibilityComponent {

}
