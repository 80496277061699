import { JsonPipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'field-errors',
  standalone: true,
  imports: [NgIf, JsonPipe],
  templateUrl: './fields-error.component.html',
  styleUrl: './fields-error.component.scss'
})
export class FieldsErrorComponent {

  @Input() formField!: FormControl;

  @Input() fieldName!: string;

}
