<div class="flex flex-col py-14 px-4 md:px-28 lg:px-72 ">
  <div class="flex flex-col items-start justify-start md:items-center md:justify-center ">
    <p class="text-black font-bold text-hurrey-20 lg:text-hurrey-24 mb-4 md:mb-5"> Get in touch</p>
    <p class="mb-10"> <span class="text-grey text-hurrey-16 lg:text-hurrey-20">Fill the details below or mail us
        at</span>
      <span class="text-hurrey-16 lg:text-hurrey-20 text-purple_100"> info&#64;hurrey.ai </span>
    </p>
  </div>


  <p class="text-hurrey-16 font-bold text-black mb-2">Which role describes you best</p>
  <div class="grid grid-cols-2 gap-4 lg:gap-6 mb-4 lg:mb-6">
    <div class="col-span-2 md:col-span-1 gap-4 checkbox_container">
      <input type="checkbox" id="csr_funder" name="csr_funder" value="csr_funder" class="checkbox-input"
        [(ngModel)]="csr_funder_checked" (change)="handleCheckboxChange('csr_funder')">
      <p class="text-hurrey-16 text-grey">CSR Funder </p>
    </div>
    <div class=" col-span-2 md:col-span-1 gap-4 checkbox_container">
      <input type="checkbox" id="system_integrator" name="system_integrator" value="system_integrator"
        class="checkbox-input" [(ngModel)]="system_integrator_checked"
        (change)="handleCheckboxChange('system_integrator')">
      <p class="text-hurrey-16 text-grey">System Integrator </p>
    </div>
    <div class=" col-span-2 md:col-span-1 gap-4 checkbox_container">
      <input type="checkbox" id="ngOs" name="ngOs" value="ngOs" class="checkbox-input" [(ngModel)]="ngOs_checked"
        (change)="handleCheckboxChange('ngOs')">
      <p class="text-hurrey-16 text-grey">NGOs</p>
    </div>
    <div class=" col-span-2 md:col-span-1 gap-4 checkbox_container">
      <input type="checkbox" id="smart_class" name="smart_class" value="smart_class" class="checkbox-input"
        [(ngModel)]=" smart_class_provider_checked" (change)="handleCheckboxChange('smart_class')">
      <p class="text-hurrey-16 text-grey">Smart Class Provider </p>
    </div>
    <div class=" col-span-2 md:col-span-1 gap-4 checkbox_container">
      <input type="checkbox" id="government_body" name="government_body" value="government_body" class="checkbox-input"
        [(ngModel)]="government_body_checked" (change)="handleCheckboxChange('government_body')">
      <p class="text-hurrey-16 text-grey">Government Body</p>
    </div>
    <div class=" col-span-2 md:col-span-1 gap-4 checkbox_container">
      <input type="checkbox" id="others" name="others" value="others" class="checkbox-input"
        [(ngModel)]="others_checked" (change)="handleCheckboxChange('others')">
      <p class="text-hurrey-16 text-grey">Other </p>
    </div>


  </div>

  <div class=" grid grid-cols-2 gap-4 lg:gap-6 mb-4 lg:mb-6" [formGroup]="getInTouchForm">
    <div class="col-span-2 flex flex-col gap-2">
      <label class="text-black text-hurrey-16 font-bold"> Name</label>
      <cust-input [required]="true" [placeholder]="'Enter your Organization name'" formControlName="name"
        [fieldName]="'name'" [type]="'text'"></cust-input>
    </div>

    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <label class="text-black text-hurrey-16 font-bold"> Email</label>
      <cust-input [required]="true" [placeholder]="'Enter your mail address'" formControlName="email"
        [fieldName]="'email'" [type]="'text'"></cust-input>
    </div>

    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <label class="text-black text-hurrey-16 font-bold"> Phone number</label>
      <cust-input [required]="true" [placeholder]="'+91 (555) 000-0000'" formControlName="phone_number"
        [fieldName]="'phone_number'"></cust-input>
    </div>

  </div>


  <p class="text-black font-bold text-hurrey-16 mb-2"> How can we help?</p>
  <textarea id="additionalComments" name="additionalComments" class="textarea mb-10"
    placeholder="Tell us a little about the project..." [(ngModel)]="description"></textarea>

  <div class="grid grid-cols-4 gap-4 md:gap-10">
    <div class="col-span-4 md:col-span-3">
      <p class="text-grey text-hurrey-16"> Verify all information are correct before clicking submit.</p>
    </div>
    <div class="col-span-4 md:col-span-1 flex items-center justify-center md:items-end md:justify-end">
      <button class="text-hurrey-16 text-white submit_button"> Submit</button>
    </div>
  </div>


</div>