import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { slideInDownOnEnterAnimation, slideInUpOnEnterAnimation } from 'angular-animations';
import { trustedBycustomers } from '../../../shared/models/trusted-by-customers.model';


@Component({
  selector: 'trusted-by-customers',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './trusted-by-customers.component.html',
  styleUrl: './trusted-by-customers.component.scss',
  animations: [
    slideInUpOnEnterAnimation({ anchor: 'slideInOut' }),
    slideInDownOnEnterAnimation({ anchor: 'slideInDown' })
  ]


})
export class TrustedByCustomersComponent {

  customers: trustedBycustomers[] = [
    {
      title: "Creative",
      description: "Seamless collaboration with Atomo, their expertise and attention",
      imgUrl: "../../../../assets/images/customer_profile_img.webp",
      name: "John Doe",
      designation: "Developer"
    },

    {
      title: "Creative",
      description: "Seamless collaboration with Atomo, their expertise and attention",
      imgUrl: "../../../../assets/images/customer_profile_img.webp",
      name: "John Doe",
      designation: "Developer"
    },
    {
      title: "Creative",
      description: "Seamless collaboration with Atomo, their expertise and attention",
      imgUrl: "../../../../assets/images/customer_profile_img.webp",
      name: "John Doe",
      designation: "Developer"
    },
    {
      title: "Creative",
      description: "Seamless collaboration with Atomo, their expertise and attention",
      imgUrl: "../../../../assets/images/customer_profile_img.webp",
      name: "John Doe",
      designation: "Developer"
    },
    {
      title: "Creative",
      description: "Seamless collaboration with Atomo, their expertise and attention",
      imgUrl: "../../../../assets/images/customer_profile_img.webp",
      name: "John Doe",
      designation: "Developer"
    },
    {
      title: "Creative",
      description: "Seamless collaboration with Atomo, their expertise and attention",
      imgUrl: "../../../../assets/images/customer_profile_img.webp",
      name: "John Doe",
      designation: "Developer"
    },
    {
      title: "Creative",
      description: "Seamless collaboration with Atomo, their expertise and attention",
      imgUrl: "../../../../assets/images/customer_profile_img.webp",
      name: "John Doe",
      designation: "Developer"
    },
  ]

  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   dots: true,
  //   infinite: true,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   speed: 300, // Animation speed
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2
  //       }
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1
  //       }
  //     }
  //   ]
  // };
}
