import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomInputComponent } from '../../../shared/reusable-components/custom-input/custom-input.component';

@Component({
  selector: 'get-in-touch-details',
  standalone: true,
  imports: [CommonModule, FormsModule, CustomInputComponent, ReactiveFormsModule,],
  templateUrl: './get-in-touch-details.component.html',
  styleUrl: './get-in-touch-details.component.scss'
})
export class GetInTouchDetailsComponent {

  csr_funder_checked: boolean = false;
  system_integrator_checked: boolean = false;
  ngOs_checked: boolean = false;
  smart_class_provider_checked: boolean = false;
  government_body_checked: boolean = false;
  others_checked: boolean = false;

  getInTouchForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone_number: new FormControl('', Validators.required)
  });



  description: string = '';


  handleCheckboxChange(checkboxName: string) {
    switch (checkboxName) {
      case 'csr_funder':
        this.csr_funder_checked = true;
        this.system_integrator_checked = false;
        this.ngOs_checked = false;
        this.smart_class_provider_checked = false;
        this.government_body_checked = false;
        this.others_checked = false;
        break;
      case 'system_integrator':
        this.csr_funder_checked = false;
        this.system_integrator_checked = true;
        this.ngOs_checked = false;
        this.smart_class_provider_checked = false;
        this.government_body_checked = false;
        this.others_checked = false;
        break;
      case 'ngOs':
        this.csr_funder_checked = false;
        this.system_integrator_checked = false;
        this.ngOs_checked = true;
        this.smart_class_provider_checked = false;
        this.government_body_checked = false;
        this.others_checked = false;
        break;
      case 'smart_class':
        this.csr_funder_checked = false;
        this.system_integrator_checked = false;
        this.ngOs_checked = false;
        this.smart_class_provider_checked = true;
        this.government_body_checked = false;
        this.others_checked = false;
        break;
      case 'government_body':
        this.csr_funder_checked = false;
        this.system_integrator_checked = false;
        this.ngOs_checked = false;
        this.smart_class_provider_checked = false;
        this.government_body_checked = true;
        this.others_checked = false;
        break;
      case 'others':
        this.csr_funder_checked = false;
        this.system_integrator_checked = false;
        this.ngOs_checked = false;
        this.smart_class_provider_checked = false;
        this.government_body_checked = false;
        this.others_checked = true;
        break;
      default:
        break;
    }
  }
}



