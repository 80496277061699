import { PricingCard } from '../models/pricing-card.model';

export const PricingContent: PricingCard[] = [
  {
    description:
      'Ideal for individuals who who need advanced features and tools for client work.',
    type: 'basic',
    details: {
      annual: false,
      currency: 'usd',
      price: 25,
      features: [
        { included: true, feature_desc: 'Upload custom icons and fonts' },
        { included: true, feature_desc: 'Unlimited Sharing' },
        {
          included: true,
          feature_desc: 'Upload graphics & video in up to 4k',
        },
        { included: true, feature_desc: 'Unlimited Projects' },
        {
          included: false,
          feature_desc: 'Instant Access to our design system',
        },
      ],
    },
  },

  {
    description:
      'Ideal for individuals who who need advanced features and tools for client work.',
    type: 'professional',
    details: {
      annual: false,
      currency: 'usd',
      price: 50,
      features: [
        { included: true, feature_desc: 'Upload custom icons and fonts' },
        { included: true, feature_desc: 'Unlimited Sharing' },
        {
          included: true,
          feature_desc: 'Upload graphics & video in up to 4k',
        },
        { included: true, feature_desc: 'Unlimited Projects' },
        {
          included: false,
          feature_desc: 'Instant Access to our design system',
        },
      ],
    },
  },

  {
    description:
      'Ideal for businesses who need services and security for large teams.',
    type: 'enterprise',
    details: {
      annual: false,
      currency: 'usd',
      price: 100,
      features: [
        { included: true, feature_desc: 'Upload custom icons and fonts' },
        { included: true, feature_desc: 'Unlimited Sharing' },
        {
          included: true,
          feature_desc: 'Upload graphics & video in up to 4k',
        },
        { included: true, feature_desc: 'Unlimited Projects' },
        {
          included: true,
          feature_desc: 'Instant Access to our design system',
        },
      ],
    },
  },
];
