<div class="price_card px-5 py-9 flex-1" [class.active]="pricingCard.type == 'professional'"
  [id]="'pricing_card_'+pricingCard.type">
  <div class="flex flex-col gap-4 mb-6">
    <p class="text-hurrey-16" [id]="pricingCard.type">{{pricingCard.type|titlecase}}</p>
    <p class="text-hurrey-16 text-grey" [class.text-white]="pricingCard.type == 'professional'"
      id="pricing_card_description">Ideal for individuals
      who who need advanced features and tools for client
      work.</p>
    <p><span class="text-hurrey-36">{{pricingCard.details.currency =='inr'? '&#8377;':'&dollar;'}}
        {{pricingCard.details.price}} </span> <span class="text-hurrey-16 text-grey"
        [class.text-white]="pricingCard.type == 'professional'">/ Month</span></p>
    <button class="w-full price_button" [class.active]="pricingCard.type == 'professional'">Get Started Now</button>
  </div>

  <div class="flex flex-col gap-3">
    <div class="flex items-center gap-3 price_item" id="pricing_card_features_container"
      *ngFor="let feature of pricingCard.details.features;let i=index">
      <div class="price_item_icon flex items-center justify-center bg-amber_20 rounded-half"
        [class.active]="pricingCard.type == 'professional'">
        <img *ngIf="feature.included && pricingCard.type != 'professional'"
          src="../../../../assets/icons/yellow_tick.svg" alt="included" [height]="16" [width]="16">
        <img *ngIf="feature.included && pricingCard.type == 'professional'" src="../../../../assets/icons/blue_tick.svg"
          alt="excluded" [height]="16" [width]="16">
        <img *ngIf="!feature.included && pricingCard.type != 'professional'"
          src="../../../../assets/icons/header_close.svg" alt="excluded" [height]="16" [width]="16">
        <img *ngIf="!feature.included && pricingCard.type == 'professional'"
          src="../../../../assets/icons/close_icon_grey.svg" alt="excluded" [height]="16" [width]="16">
      </div>
      <p class="text-hurrey-16 break-all break-words flex-" [id]="'pricing_card_feature_'+pricingCard.type+'_'+i">
        {{feature.feature_desc}}</p>
    </div>



  </div>

</div>