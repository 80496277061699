import { Component } from '@angular/core';
import { fadeInLeftOnEnterAnimation } from 'angular-animations';
import { PriceCardComponent } from '../../../shared/reusable-components/price-card/price-card.component';
import { PricingCard } from '../../../shared/models/pricing-card.model';
import { PricingContent } from '../../../shared/content/pricing-content';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [PriceCardComponent, NgFor, FormsModule],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss',
  animations: [fadeInLeftOnEnterAnimation()],
})
export class PricingComponent {
  pricingCards: PricingCard[] = PricingContent;

  yearly: boolean = false;
}
