import { Component, TemplateRef, ViewChild } from '@angular/core';
import {
  collapseOnLeaveAnimation,
  expandOnEnterAnimation,
  fadeInLeftOnEnterAnimation,
} from 'angular-animations';
import { IconsModule } from '../../icons/icons.module';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { UseCaseAccordion } from '../../../shared/models/use-case.model';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-use-cases',
  standalone: true,
  imports: [IconsModule, NgTemplateOutlet, NgFor, NgIf, NgClass, RouterLink,],
  templateUrl: './use-cases.component.html',
  styleUrl: './use-cases.component.scss',
  animations: [
    fadeInLeftOnEnterAnimation(),
    expandOnEnterAnimation({ duration: 400 }),
    collapseOnLeaveAnimation({ duration: 400 }),
  ],
})
export class UseCasesComponent {
  useCaseCards: UseCaseAccordion[] = [
    {
      head: 'Atomo for Schools',
      subHead:
        'Atomo unifies all crucial aspects of operations in one centralised view for you to manage things seamlessly.',
      isExpanded: true,
    },
    {
      head: 'Atomo for Teachers',
      subHead:
        'Atomo enriches the teaching experience by making things friendly, data-driven and efficient.',
      isExpanded: false,
    },
    {
      head: 'Atomo for Parents',
      subHead:
        "It offers personalized help, understands students' language, and gives tailored explanations for independent problem-solving.",
      isExpanded: false,
    },
    {
      head: 'Atomo for Students',
      subHead:
        'Atomo cultivates dynamic learning with personalized tools, efficient management, and collaboration.',
      isExpanded: false,
    },
    {
      head: 'Atomo for CSR Funders',
      subHead:
        'Atomo provides smart class and AI solutions for digital literacy and skill development, focusing on superior outcomes in ongoing education.',
      isExpanded: false,
    },
    {
      head: 'Atomo for System Integrators',
      subHead:
        'We team up with HP/Benq and other partners to deliver complete education packages, improving learning experiences via new and existing partnerships.',
      isExpanded: false,
    },
    {
      head: 'Atomo for Government',
      subHead:
        "Promote Atomo's solutions for efficient school management and academic monitoring.",
      isExpanded: false,
    },
    {
      head: 'Atomo for NGOs',
      subHead:
        "NGOs can boost programs with Atomo's solutions for smarter classes, yielding better outcomes.",
      isExpanded: false,
    },
    {
      head: 'Atomo for School: Comprehensive solutions',
      subHead:
        'Position Atomo as the transformative choice for modern, dynamic education by directly offering its educational solutions to schools',
      isExpanded: false,
    },
    {
      head: 'Atomo for Smart Class Providers',
      subHead:
        "Engage publishers with Atomo's unique AI-powered educational solutions.",
      isExpanded: false,
    },
  ];

  onCardClick(index: number) {
    this.useCaseCards.forEach((a, i) => {
      if (i != index) {
        a.isExpanded = false;
      }
    });
    this.useCaseCards[index].isExpanded = !this.useCaseCards[index].isExpanded;
  }
}
